import React from "react";
import { Link, graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";
// import kebabCase from "lodash.kebabcase";

import Layout from "../components/layout";
import SEO from "../components/seo";

const BlogPostTemplate = ({ data }) => {
  const post = data.markdownRemark;
  const tags = post?.frontmatter?.tags
    ?.split(",")
    .map(item => item.trim().toLowerCase());
  const { previous, next } = data;

  return (
    <Layout dark={true} absolute={false}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        image={post.frontmatter.image.childImageSharp.fluid}
        pathname={`/blog${post.fields.slug}`}
        author={post.frontmatter.author}
        publishDate={post.frontmatter.publishDate}
      />
      <BackgroundImage
        className="bg-cover bg-center bg-no-repeat page-title py-5 mb-5"
        fluid={data.hero.fluid}
      >
        <span className="bg-overlay" style={{ opacity: 0.02 }}></span>
        <div className="container bg-content py-md-5 text-left px-5">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/blog/">Blog</Link>
              </li>
            </ol>
          </nav>
          <h1 className="page-title-heading">{post.frontmatter.title}</h1>
        </div>
      </BackgroundImage>
      <article
        className="container pb-5 mb-3 blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header className="d-flex justify-content-between align-items-center pb-3">
          <div>
            <div className="post-author-name">{post.frontmatter.author}</div>
          </div>
          <div className="post-meta">
            <span className="text-sm">
              <i className="fe-icon-clock"></i> {post.frontmatter.date}
            </span>
          </div>
        </header>
        <hr className="mb-4" />
        <section
          dangerouslySetInnerHTML={{ __html: post.html }}
          itemProp="articleBody"
        />
        <hr />
        <footer>
          <div className="d-sm-flex justify-content-between pt-lg-grid-gutter pt-sm-4">
            <div className="mb-sm-0 mb-3">
              <h6 className="d-sm-inline-block mr-sm-2 mb-sm-0 mb-2">Tags:</h6>
              {tags &&
                tags.map(tag => (
                  <span key={tag} className="px-2">
                    {/* <Link
                    key={tag}
                    to={`/tags/${kebabCase(tag)}`}
                    className="btn btn-outline-primary"
                  > */}
                    {tag}
                    {/* </Link> */}
                  </span>
                ))}
            </div>
          </div>
        </footer>
      </article>
      <nav className="blog-post-nav container">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0
          }}
        >
          <li>
            {previous && (
              <Link to={`/blog${previous.fields.slug}`} rel="prev">
                ← {previous.frontmatter.title} Ue
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={`/blog${next.fields.slug}`} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    hero: imageSharp(fluid: { originalName: { eq: "blog-pattern.jpg" } }) {
      fluid(quality: 90, maxWidth: 1920) {
        presentationWidth
        presentationHeight
        ...GatsbyImageSharpFluid_withWebp
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      fields {
        slug
      }
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        publishDate: date
        author
        image {
          childImageSharp {
            fluid(quality: 90, maxWidth: 2160) {
              presentationWidth
              presentationHeight
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        tags
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`;
